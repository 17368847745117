/* eslint-disable guard-for-in */
/* eslint-disable func-names */

const applicationsRoutes = {
  my_applications: {
    url: '/applications',
    component: '/applications/list',
  },
  application_complete: {
    url: '/application/:id/complete',
    component: '/applications/complete',
  },
  application_edit: {
    url: '/application/:id/edit',
    component: '/applications/edit',
  },
  my_application_view: {
    url: '/application/:id',
    component: '/applications/view',
  },
  application_review: {
    url: '/application/:id/review',
    component: '/applications/review',
  },
};

const organisationRoutes = {
  organisation_create: {
    url: '/organisations/create',
    component: '/organisations/create',
  },
  organisation_update: {
    url: '/organisation/:slug/update',
    component: '/organisations/update',
  },
  setup_payment: {
    url: '/organisation/:slug/payment/connect',
    component: '/organisations/setup-payment',
  },
  update_payment: {
    url: '/organisation/:slug/payment/update',
    component: '/organisations/update-payment'
  },
  payment_waiting: {
    url: '/organisation/connect',
    component: '/organisations/payment-waiting',
  },
  organisation_view: {
    url: '/organisation/:slug',
    component: '/organisations/profile',
  },
  organisation_dashboard: {
    url: '/organisation/:slug/dashboard',
    component: '/organisations/dashboard',
  },
  organisation_opportunities: {
    url: '/organisation/:slug/opportunities',
    component:'/organisations/opportunities'
  },
  organisation_account: {
    url: '/organisation/:slug/payment/account',
    component: '/organisations/account',
  },
};

const personalRoutes = {
  update_profile: {
    url: '/profile/update',
    component: '/auth/update-profile',
  },
  my_opportunities: {
    url: '/opportunities',
    component: '/opportunities/my-opportunities',
  },
  review_opportunities: {
    url: '/opportunities/review',
    component: '/opportunities/review',
  },
  my_organisations: {
    url: '/organisations',
    component: '/organisations/my-organisations',
  },
  // opportunity_payment: {
  //   url: '/opportunity/:slug/payment',
  //   component: '/opportunities/payment-info',
  // },
  opportunity_payments_details: {
    url: '/opportunity/:slug/payments/details',
    component: '/opportunities/payments-details',
  },
  account_upgrade: {
    url: '/account/upgrade',
    component: '/auth/upgrade-account',
  },
  update_opportunity: {
    url: '/opportunity/:slug/edit',
    component: '/opportunities/update',
  },
  draft_opportunity: {
    url: '/opportunity/:slug/update',
    component: '/opportunities/draft',
  },
  // update_form: {
  //   url: '/opportunity/:slug/form',
  //   component: '/opportunities/form-update',
  // },
  wizard_home: {
    url: '/opportunity/wizard',
    component: '/opportunity-wizard',
  },
  opportunity_dashboard: {
    url: '/opportunity/:slug/dashboard',
    component: '/opportunities/dashboard',
  },
  insight_dashboard: {
    url: '/impact',
    component:'/insights-dashboard'
  },
  spaces: {
    url: '/spaces',
    component: '/spaces',
    
  },
  space_detail: {
    name: 'spacePage',
    url: '/spaces/:id',
    component: '/spaces/detail',
  },
}

const routes = {
  homepage: {
    url: '/',
    component: '/index',
  },
  login: {
    url: '/login',
    component: '/auth/login',
  },
  register: {
    url: '/register',
    component: '/auth/register',
  },
  register_complete: {
    url: '/register/complete',
    component: '/auth/register-complete',
  },
  pass_forgot: {
    url: '/password/forgot',
    component: '/auth/forgot-password',
  },
  pass_reset: {
    url: '/password/reset',
    component: '/auth/update-password',
  },
  verify_account: {
    url: '/verify',
    component: '/auth/verify',
  },
  verify_payment_account: {
    url: '/organisation/:slug/account/verify',
    component: '/organisations/verify-account'
  },
  opportunity_search: {
    url: '/opportunity/search',
    component: '/opportunities/search',
  },
  about_us: {
    url: '/about',
    component: '/static/about',
  },
  terms: {
    url: '/terms-conditions',
    component: '/static/terms',
  },
  contact: {
    url: '/contact',
    component: '/static/contact',
  },
  help: {
    url: '/help',
    component: '/static/help',
  },
  privacy: {
    url: '/privacy',
    component: '/static/privacy',
  },
  application_payment: {
    url: '/application/:id/pay',
    component: '/applications/pay',
  },
  form_viewer: {
    url: '/opportunity/:slug/apply',
    component: '/opportunities/form-viewer',
  },
  view_opportunity: {
    url: '/opportunity/:slug',
    component: '/opportunities/view',
  },
  // organisation_overview: {
  //   url: '/organisation/:slug/overview',
  //   component: '/organisations/overview',
  // },
  // organisation_chat: {
  //   url: '/organisation/:slug/chat',
  //   component: '/organisations/chat',
  // },
  // organisation_members: {
  //   url: '/organisation/:slug/members',
  //   component: '/organisations/members',
  // },
  ...applicationsRoutes,
  ...organisationRoutes,
  ...personalRoutes,
};

const routing = function (app, nextApp) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in routes) {
    app.get(routes[key].url, (req, res) => {
      const { query, params, cookies } = req;
      const {token} = cookies;
      
      if (!token && (key in applicationsRoutes || key in organisationRoutes || key in personalRoutes)) {
        const preLoginUrl = req.originalUrl;
        const next = encodeURIComponent(preLoginUrl);
        return res.redirect(`/login?next=${next}`);
      }

      return nextApp.render(req, res, routes[key].component, { ...query, ...params });
    });
  }
};

const getRouteName = function (route) {
  const routeKey = Object.keys(routes).find((key) => routes[key].component === route);
  if (routeKey) {
    return routes[routeKey].name;
  }
  return null;
};
exports.routes = routes;
exports.routing = routing;
exports.getRouteName = getRouteName;
